import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import {GuardiaGuard} from './guardia.guard';
import { AuthGuard } from './core/guard/auth.guard';
import {PermitGuard} from './permit.guard'
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';


const routes: Routes = [
  { path:'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard, PermitGuard],
    children: [
      {
        path: 'paciente',
        loadChildren: () => import('./views/pages/paciente/paciente.module').then(m => m.PacienteModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      {
        path: 'consultas',
        loadChildren: () => import('./views/pages/consultas/consultas.module').then(m => m.ConsultasModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      {
        path: 'formulario-consulta',
        loadChildren: () => import('./views/pages/formulario-consulta/formulario-consulta.module').then(m => m.FormularioConsultaModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./views/pages/usuarios/usuarios.module').then(m => m.UsuariosModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      {
        path: 'grupos',
        loadChildren: () => import('./views/pages/grupos/grupos.module').then(m => m.GruposModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      {
        path:'msm-whatsapp',
        loadChildren: () => import('./views/pages/msm-whatsapp/msm-whatsapp.module').then(m => m.MsmWhatsappModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      {
        path: 'apps',
        loadChildren: () => import('./views/pages/apps/apps.module').then(m => m.AppsModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      {
        path: 'tables',
        loadChildren: () => import('./views/pages/tables/tables.module').then(m => m.TablesModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      {
        path: 'reg-pacientes',
        loadChildren: () => import('./views/pages/reg-pacientes/reg-pacientes.module').then(m => m.RegPacienteModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      {
        path: 'reg-consulta',
        loadChildren: () => import('./views/pages/reg-consulta/reg-pacientes.module').then(m => m.RegConsultaModule),
        canActivate:[GuardiaGuard, PermitGuard]
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' }, 
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  { 
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
