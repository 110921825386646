import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { GruposComponent } from './views/pages/grupos/grupos.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormularioConsultaComponent } from './views/pages/formulario-consulta/formulario-consulta.component';
//import {FormElementsComponent} from './views/pages/form-elements/form-elements.component';
import { CookieService } from 'ngx-cookie-service';
import {HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
//import { RegPacientesComponent } from './views/pages/reg-pacientes/reg-pacientes.component';
//import { BusquedaComponent } from './busqueda/busqueda.component'


@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    FormularioConsultaComponent,
    //RegPacientesComponent,
    //FormElementsComponent
    //BusquedaComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    NgbModule,FormsModule,
    ReactiveFormsModule,
     
  ],
  providers: [
    AuthGuard,
    CookieService,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
