import { Injectable } from '@angular/core';
import { WebrequestService } from './webrequest.service';
import { HttpClient } from '@angular/common/http'



@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private webReqService: WebrequestService, private http:HttpClient) { }
  getPermissions(token:string){
    return this.webReqService.post('User/getpermissions',{token});
  }
  getUsers(token:string){
    return this.webReqService.post('Users/get',{token}); 
  }
  getGroups(token:string){
    return this.webReqService.post('groups/get',{token});
  }
  createUser(nombre:string, clave:string, correo: string, usuario:string, grupo:number, token:string){
    return this.webReqService.post('Users/create', {nombre, clave, correo, usuario, grupo, token});
  }
  updateUser( nombre:string, clave:string, correo: string, usuario:string, grupo:number, idusuario:number, token:string){
    return this.webReqService.post('Users/update',{nombre, clave, correo, usuario, grupo, idusuario, token});
  }
  deleteUser(idusuario:number, token:string){
    return this.webReqService.post('Users/delete',{idusuario, token});
  }
  createGroup(nombre:string, res1:number, res2:number, res3:number, res4:number, res5:number, res6:number, res7:number, res8:number, res9:number, token:string){
    return this.webReqService.post('groups/create', {nombre, res1, res2, res3, res4, res5, res6, res7, res8, res9, token});
  }
  deleteGroup(idgrupo:number, token:string){
    return this.webReqService.post('groups/delete',{idgrupo, token});
  }
  updateGroup(idgrupo:number, nombre:string, res1:number, res2:number, res3:number, res4:number, res5:number, res6:number, res7:number, res8:number, res9:number, token:string){
    return this.webReqService.post('groups/update', {idgrupo, nombre, res1, res2, res3, res4, res5, res6, res7, res8, res9, token});
  }
  createPatient(
    fecha:string,
    nombre:string,
    direccion:string,
    telefono:string,
    ocupacion:string,
    edad:string,
    referencia:string,
    correo:string,
    ev_visual:number,
    enfermedad:number,
    computadora:number,
    lentes:number,
    antecedentes:number,
    cefalea:number,
    vision_borrosa:number,
    dolor:number,
    ojo_rojo:number,
    fotofobia:number,
    glaucoma:number,
    diabetes:number,
    secreciones:number,
    cansancio:number,
    volantes:number,
    ardor:number,
    embarazo:number,
    presion:number,
    cx:number,
    otros:number,
    anexos:string,
    fondo:string,
    observaciones:string,
    black_list:number,
    token:string,
    ob_ev_visual:string, 
    ob_enfermedad:string, 
    ob_computadora:string, 
    ob_lentes:string, 
    ob_antecedentes:string ){
    return this.webReqService.post('patient/create', {fecha,nombre,direccion,telefono,ocupacion,edad,referencia,correo,ev_visual,enfermedad,computadora,lentes,antecedentes,cefalea,vision_borrosa,dolor,ojo_rojo,fotofobia,glaucoma,diabetes,secreciones,cansancio,volantes,ardor,embarazo,presion,cx,otros,anexos,fondo,observaciones,black_list,token,ob_ev_visual, ob_enfermedad, ob_computadora, ob_lentes, ob_antecedentes});
  
  }
  createConsultation(
    fecha:string,
    od_sl:string,
    od_cl:string,
    oi_sl:string,
    oi_cl:string,
    od_len1:string,
    od_len2:string,
    od_len3:string,
    oi_len1:string,
    oi_len2:string,
    oi_len3:string,
    rx_od1:string,
    rx_od2:string,
    rx_od3:string,
    rx_od4:string,
    rx_oi1:string,
    rx_oi2:string, 
    rx_oi3:string,
    rx_oi4:string,
    ret_od1:string,
    ret_od2:string,
    ret_od3:string,
    ret_oi1:string,
    ret_oi2:string,
    ret_oi3:string,
    rx:string,
    paciente:number,
    token:string,
    od_len4:string,
    oi_len4:string,
    ret_od4:string,
    ret_oi4:string,
    rx_od5:string, 
    rx_oi5:string,
    edad:string
  ){
    return this.webReqService.post('consultation/create', {fecha,od_sl,od_cl,oi_sl,oi_cl,od_len1,od_len2,od_len3,oi_len1,oi_len2,oi_len3,rx_od1,rx_od2,rx_od3,rx_od4,rx_oi1,rx_oi2,rx_oi3,rx_oi4,ret_od1,ret_od2,ret_od3,ret_oi1,ret_oi2,ret_oi3,rx,paciente,token, od_len4, oi_len4, ret_od4, ret_oi4, rx_od5, rx_oi5,edad});
  }
  getPatients(){
    return this.webReqService.get('patient/get',"");
  }
  findPatients(find:string, token: string){
    return this.webReqService.post('patient/find', {find, token});
  }
  findConsultation(idpaciente:string, token:string){
    return this.webReqService.post('consultation/find', {idpaciente, token});
  }
  deletePatient(idpaciente:number, token:string){
    return this.webReqService.post('patient/delete',{idpaciente, token});
  }
  deleteConsultation(idconsulta:number, token:string){
    return this.webReqService.post('consultation/delete',{idconsulta, token});
  }
  blackList( idpaciente :number, black_list:number, token:string){
    return this.webReqService.post('patient/blacklist',{black_list, idpaciente, token});
  }
  updatePatient( fecha:string,
    nombre:string,
    direccion:string,
    telefono:string,
    ocupacion:string,
    edad:string,
    referencia:string,
    correo:string,
    ev_visual:number,
    enfermedad:number,
    computadora:number,
    lentes:number,
    antecedentes:number,
    cefalea:number,
    vision_borrosa:number,
    dolor:number,
    ojo_rojo:number,
    fotofobia:number,
    glaucoma:number,
    diabetes:number,
    secreciones:number,
    cansancio:number,
    volantes:number,
    ardor:number,
    embarazo:number,
    presion:number,
    cx:number,
    otros:number,
    anexos:string,
    fondo:string,
    observaciones:string,
    idpaciente:number,
    token:string,
    ob_ev_visual:string,
    ob_enfermedad:string,
    ob_computadora:string,
    ob_lentes:string,
    ob_antecedentes:string
    ){
    return this.webReqService.post('patient/update', {fecha,nombre,direccion,telefono,ocupacion,edad,referencia,correo,ev_visual,enfermedad,computadora,lentes,antecedentes,cefalea,vision_borrosa,dolor,ojo_rojo,fotofobia,glaucoma,diabetes,secreciones,cansancio,volantes,ardor,embarazo,presion,cx,otros,anexos,fondo,observaciones, idpaciente,token, ob_ev_visual, ob_enfermedad, ob_computadora, ob_lentes, ob_antecedentes});
    }
  login(usuario:string, pass:string){
    return this.webReqService.post('login',{usuario, pass});
  }
  updateConsultation(
    fecha:string,
    od_sl:string,
    od_cl:string,
    oi_sl:string,
    oi_cl:string,
    od_len1:string,
    od_len2:string,
    od_len3:string,
    oi_len1:string,
    oi_len2:string,
    oi_len3:string,
    rx_od1:string,
    rx_od2:string,
    rx_od3:string,
    rx_od4:string,
    rx_oi1:string,
    rx_oi2:string, 
    rx_oi3:string,
    rx_oi4:string,
    ret_od1:string,
    ret_od2:string,
    ret_od3:string,
    ret_oi1:string,
    ret_oi2:string,
    ret_oi3:string,
    rx:string,
    paciente:number,
    token:string,
    od_len4:string,
    oi_len4:string,
    ret_od4:string,
    ret_oi4:string,
    id_consulta:number,
    rx_od5:string, 
    rx_oi5:string,
    edad:string
  ){
    return this.webReqService.post('consultation/update', {fecha,od_sl,od_cl,oi_sl,oi_cl,od_len1,od_len2,od_len3,oi_len1,oi_len2,oi_len3,rx_od1,rx_od2,rx_od3,rx_od4,rx_oi1,rx_oi2,rx_oi3,rx_oi4,ret_od1,ret_od2,ret_od3,ret_oi1,ret_oi2,ret_oi3,rx,paciente,token, od_len4, oi_len4, ret_od4, ret_oi4, id_consulta, rx_od5, rx_oi5, edad});
  }


  createCita(fecha:string, hora:string, doctor:string, paciente:string,  token:string, nombrePaciente:string, color:string){
    return this.webReqService.post('dates/create',{fecha, hora, doctor, paciente, token, nombrePaciente, color});
  }
  deleteCita(token:string, idcita:string){
    return this.webReqService.post('dates/delete',{ token, idcita});
  }

  getCitas(token:string){
    return this.webReqService.post('dates/get',{token});
  }
  getCita(token:string, id:string){
    return this.webReqService.post('dates/getDate',{token, id});
  }
  updateCita(token:string, hora:string, fecha:string, doctor:string, idcita:string){
    return this.webReqService.post('dates/update',{token,hora, fecha, doctor, idcita});
  }

  getMessage(token:string){
    return this.webReqService.post('message/get', {token});
  }
  updateMessage(token:string, message:string){
    return this.webReqService.post('message/update', {token, message});
  }
}