// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//let baseURL = 'https://jymapi.devbluedevs.com:3008';     //servidor
let baseURL             = 'http://apisistema.jymoptica.com.gt:3008';     //local
let userController      = '/users';
let companyController   = '/company';
let factoringController = '/factoring';
let emissorController   = '/emissor';
let receptorController   = '/receptor';
let catalogController   = '/catalog';
let statisticsController = '/statistics';
let invoiceController = '/invoiceLoad';
let reportsController = '/reports';
export const environment = {
  production: false,
  //BD
  baseURL : 'baseURL',
  //cookies
  tokenID       : 'session_token',
  userdata      : 'session_user',
  expiresInID   : 'token_expires_in',
  companyID     : 'companyID',
  companyAlias  : 'companyAlias',
  companyNIT    : 'companyNIT',
  isInfile      : 'is_infile',
  proveedor     : 'is_emitter',
  grancomprador : 'is_receiveer',
  factoraje     : 'is_factoring',
  certificador  : 'is_certifier',
  // ER
  ER_decimal: '^[0-9]+.([.][0-9]{1,2})?$',
  ER_discount: '^[0]([.][0-9]{1,2})?$',
  // TITULOS DE MENU
  //temporales
  menu_ofertasRecibidasEmpresas:'ofertas recibidas empresas',
  //Comunes
    // Empresa
      menu_administracionInfile: 'Administración Infile',
      menu_empresa: 'Empresa',
      menu_usuarios: 'Usuarios',
    //factoraje
      menu_factorajeTitulo: 'Factoraje',
      menu_factoraje: 'Factoraje ',
    //reportes
      menu_reportes:'Reportes',
      menu_reportes_bitacora:'Bitácora',
      menu_reportes_facturas:'Reporte de facturas',
      menu_reportes_factoraje:'Reporte de Factoraje',
      menu_reportes_estadisticas_facturas:'Estadísticas de facturas',
      menu_reportes_estadisticas_factoraje:'Estadísticas de factoraje',
      menu_reportes_negociaciones:'Reporte de Negociaciones',
      menu_reportes_ranking:'Ranking de empresas',
      menu_reportes_generales:'Reportes Generales',
      menu_reportes_titulo_proveedores:'Reportes de Proveedores',
      menu_reportes_titulo_compradores:'Reportes de Compradores',
      menu_reportes_titulo_factoraje  :'Reportes de Factoraje',
      menu_reportes_ventas:'Reporte de ventas',
      menu_reportes_compras:'Reporte de compras',
      menu_reportes_proveedores:'Reporte de proveedores',
      menu_reportes_compradores:'Reporte de compradores',
      menu_reportes_listado_empresas:'Reporte Lista de Empresas',
      menu_reportes_ofertas_factoraje:'Reporte de Ofertas',
  //Gran Comprador
    //proveedores
      menu_proveedores:'Proveedores',
      menu_listadoProveedores: 'Listado de Proveedores',
      menu_cargaMasivaProveedores:'[x]Carga Masiva Proveedores',
      menu_contactosProveedores:'[x]Contactos',
      menu_solicitudesProveedor:'Solicitudes',
    //mis compras
      menu_misCompras:'Mis Compras',
      menu_ListadoDeCompras:'Listado de Compras',//cuentas por pagar
      menu_CargaDeCompras:'Carga De Compras',
      menu_SincronizacionContraseniaPago:'Envio Contraseñas Pago',

  //Proveedor
    //clientes
      menu_clientes:'Clientes',
      menu_listadoClientes: 'Listado de Clientes',
      menu_cargaMasivaClientes: '[x]Carga Masiva Clientes',
      menu_contactosClientes:'[x]Contactos',
      menu_solicitudesCliente:'Solicitudes',

    //mis ventas
      menu_misVentas:'Mis Ventas',
      menu_listadoDeVentas:'Listado de Ventas',//cuentas por cobrar
      menu_cargaDeVentas:'Carga de Ventas',
      menu_solicitarFactoraje: 'Solicitar Factoraje',
    //factoraje
      menu_misFacturasEnFactoraje : 'Mis Facturas en Factoraje',
      menu_misOfertasDeFactoraje : 'Mis Ofergas de Factoraje',
      menu_subirDocumentor : 'Subir Documentos',

  //Factores
    //Clientes
      menu_clientesFactorajeTitulo: 'Clientes',
      menu_clientesDeFactorajeTitulo: '[x]Clientes De Factoraje[x]',
      menu_clientesDeFactoraje: '[x]Listado de Clientes',
    //Transacciones
      menu_transacciones:'Transacciones',
      menu_facturasEnSubasta: 'Listado de facturas en subasta',
      menu_ofertasRealizadasFactoraje : 'Ofertas Realizadas',
      menu_facturasConFactoraje:'Facturas con Factoraje',



  //rememberme cookie
  rememberme: 'rememberme',

  //Session
  loginURL: baseURL + '/users/login',
  checkTokenURL: baseURL + '/users/check',

  //Users
  getUsers                    : baseURL + userController + '/getUsers',
  getAdminUsers               : baseURL + userController + '/getAdminUsers',
  getInfileUsers              : baseURL + userController + '/getInfileUsers',
  isInfileUser                : baseURL + userController + '/isInfileUser',
  userExists                  : baseURL + userController + '/userExists',
  getUserByID                 : baseURL + userController + '/getUserByID',
  getUserByUsername           : baseURL + userController + '/getUserByUsername',
  getRolePermitsURL           : baseURL + userController + '/getRolePermits',
  getUserPermitsURL           : baseURL + userController + '/getUserPermits',
  getUserByRoleURL            : baseURL + userController + '/getUserByRole',
  createUser                  : baseURL + userController + '/createUser',
  createUserCompanyReg        : baseURL + userController + '/createUserCompanyRegister',
  updateUser                  : baseURL + userController + '/updateUser/',
  deleteUser                  : baseURL + userController + '/deleteUser/',
  passRecovery                : baseURL + userController + '/recoveryPassword/',
  passReset                   : baseURL + userController + '/resetPassword/',
  //roles
  getRoles                    : baseURL + userController + '/getRoles',
  getNonAdminRoles            : baseURL + userController + '/getNonAdminRoles',
  getRoleByID                 : baseURL + userController + '/getRoleByID',
  createRole                  : baseURL + userController + '/createRole',
  updateRolePermits           : baseURL + userController + '/updateRolePermits',
  getCompanyUserPermits       : baseURL + userController + '/getCompanyUserPermits/',
  //Companies
  isUserInCompany             : baseURL + companyController + '/isUserInCompany',
  createCompany               : baseURL + companyController + '/createCompany',
  getCompanies                : baseURL + companyController + '/getCompanies',
  getCompanyList              : baseURL + companyController + '/getCompanyList',
  getCompanyClientsList       : baseURL + companyController + '/getCompanyClientsList',
  getCompanyProvidersList     : baseURL + companyController + '/getCompanyProvidersList',
  getUnverifiedCompanyList    : baseURL + companyController + '/getUnverifiedCompanyList',
  getCompanyRolesByID         : baseURL + companyController + '/getCompanyRolesByID',
  getCompanyByAlias           : baseURL + companyController + '/getCompanyByAlias',
  getCompanyByID              : baseURL + companyController + '/getCompanyByID',
  authorizeCompany            : baseURL + companyController + '/authorize/',
  verifyCompany               : baseURL + companyController + '/verify/',
  suspendCompany              : baseURL + companyController + '/suspend/',
  updateCompanyLastLogin      : baseURL + companyController + '/updateLastLogin/',
  updateCompany               : baseURL + companyController + '/updateCompany/',
  addProviderRequest          : baseURL + companyController + '/addProvider/',
  acceptProviderRequest       : baseURL + companyController + '/acceptProvider/',
  rejectProviderRequest       : baseURL + companyController + '/rejectProvider/',
  addClientRequest            : baseURL + companyController + '/addClient/',
  acceptClientRequest         : baseURL + companyController + '/acceptClient/',
  rejectClientRequest         : baseURL + companyController + '/rejectClient/',
  addUserToCompany            : baseURL + companyController + '/addUserToCompany/',
  deleteCompanyUser           : baseURL + companyController + '/deleteCompanyUser/',
  addAPIdata                  : baseURL + companyController + '/addAPIdata',
  //ratings
  getPendingRatings           : baseURL + companyController + '/getPendingRatings',
  rateCompany                 : baseURL + companyController + '/rateCompany',
  //* Statistics
  saveCompanyRating           : baseURL + statisticsController + '/saveCompanyRating/',
  //Reportes
  getInvoiceMetrics           : baseURL + reportsController + '/getInvoiceMetrics',
  getInvoicesReport           : baseURL + reportsController + '/getInvoicesReport',
  getFactoringInvoicesReport  : baseURL + reportsController + '/getFactoringInvoicesReport',
  getNegotiationsReport       : baseURL + reportsController + '/getNegotiationsReport',
  getBinnacleReport           : baseURL + reportsController + '/getBinnacleReport',
  getFactoringInvoiceMetrics  : baseURL + reportsController + '/getFactoringInvoiceMetrics',
  getRankingReport            : baseURL + reportsController + '/getRankingReport',
  getClientsReport            : baseURL + reportsController + '/getClientsReport',
  getProvidersReport          : baseURL + reportsController + '/getProvidersReport',
  getSalesReport              : baseURL + reportsController + '/getSalesReport',
  getPurchasesReport          : baseURL + reportsController + '/getPurchasesReport',
  getCompaniesReport          : baseURL + reportsController + '/getCompaniesReport',
  getFactoringOffersReport    : baseURL + reportsController + '/getFactoringOffersReport',
  getReceivableReport         : baseURL + reportsController + '/getReceivableReport',
  getPayableReport            : baseURL + reportsController + '/getPayableReport',

  getTop10Providers  : baseURL + reportsController + '/getTop10Providers',
  getTop10BuyersBySales  : baseURL + reportsController + '/getTop10BuyersBySales',
  getTop10BuyersByAmountDiscount  : baseURL + reportsController + '/getTop10BuyersByAmountDiscount',
  getTotalInvoicesByState  : baseURL + reportsController + '/getTotalInvoicesByState',
  getTotalInvoicesByFactoringState  : baseURL + reportsController + '/getTotalInvoicesByFactoringState',
  getTotalOverdueInvoices  : baseURL + reportsController + '/getTotalOverdueInvoices',
  //* Factoring
  // Invoice Load
  getInvoicesByCompanyIDtoRequestFactoring : baseURL + factoringController + invoiceController+ '/getInvoicesByCompanyIDtoRequestFactoring',
  getInvoicesEnvioContraseniaPago:baseURL+ factoringController + '/getInvoicesEnvioContraseniaPago',
  getPurchasesByCompanyID     : baseURL + factoringController + '/getPurchasesByCompanyID',
  getSalesByCompanyID         : baseURL + factoringController + '/getSalesByCompanyID',
  loadManualInvoice           : baseURL + factoringController + invoiceController + '/invoiceLoad',
  loadXMLFile                 : baseURL + factoringController + invoiceController + '/uploadInvoiceXML',
  getInvoiceByCompanyId       : baseURL + factoringController + invoiceController + '/getInvoiceByCompanyId',
  getOfferedInvoicesToOffer   : baseURL + factoringController + invoiceController + '/getOfferedInvoicesToOffer',
  // SearchByParameters       : baseURL + factoringController + invoiceController + '/SearchByParameters',
  SearchByParameters          : baseURL + factoringController + invoiceController + '/FilterInvoices',
  saveInvoicesFromApi         : baseURL + factoringController + invoiceController + '/saveIncoicesFromAPI',
  offerInvoices               : baseURL + factoringController + invoiceController + '/offerInvoices',
  startFactoring              : baseURL + factoringController + invoiceController + '/startFactoring',
  // renegotiateOfferByOfferID   : baseURL + factoringController + invoiceController + '/renegotiateOfferByOfferID',
  renegotiateOfferByOfferID   : baseURL + factoringController + '/renegotiateOfferByOfferID',
  invoiceSettlement           : baseURL + factoringController + invoiceController + '/invoiceSettlement',
  getOfferByOfferID           : baseURL + factoringController + invoiceController + '/getOfferByOfferID',
  saveFileContraseniaDePago   : baseURL + factoringController + invoiceController + '/saveFileContraseniaDePago',
  saveFile                    : baseURL + factoringController + invoiceController + '/saveFile',
  getAttachementsByIdInvoice  : baseURL + factoringController + invoiceController + '/getAttachementsByIdInvoice',
  getEmitterStates            : baseURL + factoringController + invoiceController + '/getEmitterStates',
  getReceiverStates           : baseURL + factoringController + invoiceController + '/getReceiverStates',
  rejectInvoiceSettlement     : baseURL + factoringController + '/rejectInvoiceSettlement',
  sendInvoiceToCustomer       : baseURL + factoringController + '/sendInvoiceToCustomer',
  markAsPaid                  : baseURL + factoringController + '/markAsPaid',
  acceptFactoringOffer        : baseURL + factoringController + '/acceptFactoringOffer',
  rejectFactoringOffer        : baseURL + factoringController + '/rejectFactoringOffer',
  getOffersByFactoringID      : baseURL + factoringController + '/getOffersByFactoringID',
  getAcceptedOffersByProviderNIT  : baseURL + factoringController + '/getAcceptedOffersByProviderNIT',
  getAcceptedOffersByFactoringID  : baseURL + factoringController + '/getAcceptedOffersByFactoringID',
  getAllCompanyOffers         : baseURL + factoringController + '/getAllCompanyOffers',
  getRecievedOffersByCompanyID :baseURL + factoringController + '/getRecievedOffersByCompanyID',
  // Attachements
  // Emissor Data
  getEmissorByNit             : baseURL + emissorController + '/getEmissorByNit',
  // Receptor Data
  getReceptorByReceptorID     : baseURL + receptorController + '/getReceptorByReceptorID',
  //* Helpers
  getGeneralStates                      : baseURL + catalogController + '/getGeneralStates',
  getPaymentStates                      : baseURL + catalogController + '/getPaymentStates',
  getFactoringStates                    : baseURL + catalogController + '/getFactoringStates',
  getStatesForFactoringMetrics          : baseURL + catalogController + '/getStatesForFactoringMetrics',
  getCurrencies                         : baseURL + catalogController + '/getCurrencies',


  //* External APIS
  //verify NIT API
  NITAPIverify                : baseURL + factoringController + '/verifyNIT',//'/rest/action',//recortada por uso de proxy.conf.json
  // NITAPIemisor_codigo: 'DEMOBLUDEVS',
  // NITAPIemisor_clave: '6CF640CEB8C83840E92D6070F7A864B4',
  //get invoices API
  InvoiceAPIauthorize         : baseURL + factoringController + '/getAuthorization',
  InvoiceAPIload              : baseURL + factoringController + '/getFactoringAPIInvoiceData',
  // InvoiceAPIuser:'DEMOBLUDEVS',
  // InvoiceAPInit:'92000000166K',
  // InvoiceAPIkey:'6CF640CEB8C83840E92D6070F7A864B4',
  // InvoiceAPIauthToken:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2Mjg1MjgwMzcsImV4cCI6MTY2MDA2NDAzNywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.3ySd8NhG8-r_gLdDldRMHS2BapQrSy2WhkdzQPLL_EA',
  // InvoiceAPIdataToken:'data_token',
  //etc
  swalTimer : 3500,
  swalTimer_corto : 1000,
  stringInfile : 'Infile',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
