import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formulario-consulta',
  templateUrl: './formulario-consulta.component.html',
  styleUrls: ['./formulario-consulta.component.scss']
})
export class FormularioConsultaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
