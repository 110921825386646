import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { TaskService } from 'src/app/task.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private taskService: TaskService,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router
  ) { }
  public usuario;
  public correo;
  ngOnInit(): void {
    this.usuario = this.cookieService.get('usr');
    this.correo = this.cookieService.get('correo');
    
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('isLoggedin');

    if (!localStorage.getItem('isLoggedin')) {
      this.cookieService.deleteAll();
      this.cookieService.delete('Mant_usr');
      this.cookieService.delete('Mant_grp');
      this.cookieService.delete('Mant_msm');
      this.cookieService.delete('Reg_pac');
      this.cookieService.delete('Reg_cons');
      this.cookieService.delete('Busq');
      this.cookieService.delete('Edit');
      this.cookieService.delete('Crear');
      this.cookieService.delete('token');
      this.cookieService.delete('usr');

      this.router.navigate(['/auth/login']);
    }
  }

}
